import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import getString from '../utils/get-string';

const ResetPasswordAffirmation = ({strings}) => (
  <Layout>
    <Seo title={getString(strings, "11248658")} />

    <Page type="success">
      <Header closeUrl="/lessons">{getString(strings, "11220916")}</Header>
      <Content center={true}>
        <h1 className="h2 growing text-transform-uppercase">
          {getString(strings, "11248658")}
        </h1>
      </Content>
      <Footer>
        <FooterLinkNext to="/login">{getString(strings, "11220911")}</FooterLinkNext>
      </Footer>
    </Page>
  </Layout>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11248658", "11220916", "11220911"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <ResetPasswordAffirmation {...data} {...props} />}
  />
)
